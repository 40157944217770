'use client';

import ClientSlugPage from 'components/PageLevelComponents/ClientSlugPage';

import type { IGlobalMetaData } from 'types/GlobalMetadata';
import type { IBreadcrumbTitles } from 'components/Breadcrumbs';
import type { ISearchOverlay } from 'components/HeaderNavigation/components/MobileNavigation';
import type { DictionaryItem } from 'service/dictionary/dictionaryService';

const ClientInspirationSlugPage = (props: {
  breadcrumbsCMSData: IBreadcrumbTitles;
  data: any;
  globalMetadata: IGlobalMetaData;
  searchOverlayData: ISearchOverlay;
  recipeCopy: DictionaryItem[];
  difficultyCopy: Record<string, string>;
  searchItemLabels: Record<string, string>;
}) => <ClientSlugPage {...props} />;

export default ClientInspirationSlugPage;
